<template>
  <PSignupForm @submit="handleSubmit">
    <template slot="title">
      {{ $t('ユーザー登録') }}
    </template>
    <template slot="contents">
      <p
        class="p-authSection__lead content"
        v-t="
          '新規ユーザーアカウントを作成します。\nパスワードは、半角英数字（※英字は大文字・小文字）を含む8桁以上で設定してください。\nボタン押下後、入力したメールアドレスに「検証コード」を送信します。'
        "
      />
      <div class="p-authInputs">
        <PAuthInput
          class="p-authInputs__input"
          type="email"
          v-model.trim="$v.email.$model"
          :placeholder="$t('メールアドレス')"
          required
          autocomplete="email"
          :is-error="emailError"
        />
        <PAuthInput
          class="p-authInputs__input"
          type="password"
          v-model.trim="$v.password.$model"
          :placeholder="$t('パスワード')"
          required
          autocomplete="new-password"
          :is-error="passwordError"
        />
        <PAuthInput
          class="p-authInputs__input"
          type="password"
          v-model.trim="$v.passwordConfirm.$model"
          :placeholder="$t('パスワード（確認）')"
          required
          autocomplete="new-password"
          :is-error="passwordConfirmError"
        />
        <p
          v-if="passwordConfirmFailed"
          class="p-authInputs__errorText"
          v-t="'パスワードと、パスワード（確認）が一致しません。'"
        />
        <p
          v-else-if="errorCode"
          class="p-authInputs__errorText"
          v-t="getErrorMessage(errorCode)"
        />
      </div>
      <p
        class="p-authSection__annotation"
        v-t="
          'メールが受信できない場合は「@trust-shop.hitachi.co.jp」のドメインを受信する設定にご変更ください。'
        "
      />
      <button
        type="submit"
        class="p-authSection__button c-button"
        v-t="'登録する'"
        :disabled="$v.$invalid"
      />
      <div class="p-authSection__link">
        <app-link
          class="c-link"
          :to="toLoginRoute"
          v-t="'ログイン画面に戻る'"
        />
      </div>
    </template>
  </PSignupForm>
</template>

<script>
import PAuthInput from '@/components/auth/PAuthInput.vue'
import PSignupForm from '@/components/auth/PSignupForm.vue'
import { required, email } from 'vuelidate/lib/validators'

export default {
  name: 'SignupPresentation',
  components: {
    PAuthInput,
    PSignupForm,
  },
  props: {
    errorCode: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      email: '',
      password: '',
      passwordConfirm: '',
      passwordConfirmFailed: false,
    }
  },
  validations: {
    email: {
      required,
      email,
    },
    password: {
      required,
    },
    passwordConfirm: {
      required,
    },
  },
  computed: {
    emailError() {
      return (
        this.$v.email.$error ||
        (!this.passwordConfirmFailed &&
          this.errorCode === 'UsernameExistsException')
      )
    },
    passwordError() {
      return (
        this.$v.password.$error ||
        this.passwordConfirmFailed ||
        this.errorCode === 'InvalidPasswordException'
      )
    },
    passwordConfirmError() {
      return (
        this.$v.passwordConfirm.$error ||
        this.passwordConfirmFailed ||
        this.errorCode === 'InvalidPasswordException'
      )
    },
    toLoginRoute() {
      const result = {
        name: 'Login',
        params: {
          portalId: this.portalId,
        },
      }

      if (this.$route.query.redirect) {
        result.query = {
          isSecurityUserAccess: true,
          redirect: this.$route.query.redirect,
        }
      }

      return result
    },
  },
  methods: {
    handleSubmit() {
      // パスワードとパスワード（確認）値が一致しているか確認
      this.passwordConfirmFailed = this.password !== this.passwordConfirm

      if (!this.passwordConfirmFailed) {
        this.$emit('signup', {
          username: this.email,
          password: this.password,
        })
      }
    },
    getErrorMessage(code) {
      switch (code) {
        case 'UsernameExistsException':
          return 'すでにユーザーが登録されています。検証コードの入力が完了していない場合は、ログイン画面からログインし、ユーザー登録を完了してください。パスワードを忘れた場合は、ログイン画面からパスワード変更を行ってください。'
        case 'InvalidPasswordException':
          return 'パスワードは、大文字、小文字、数字を含む、8桁以上の値を設定してください。'
      }
    },
  },
}
</script>
