<template>
  <SignupPresentation @signup="handleSignup" :error-code="errorCode" />
</template>

<script>
import SignupPresentation from '@/components/auth/SignupPresentation.vue'
import { cognitoSignUp } from '@/assets/modules/cognito'
import store from '@/store'

export default {
  name: 'Signup',
  components: { SignupPresentation },
  metaInfo() {
    return {
      title: this.$t('ユーザー登録'),
    }
  },
  data() {
    return {
      errorCode: '',
    }
  },
  beforeRouteEnter(to, _from, next) {
    // ログイン済みの場合トップにリダイレクト
    if (store.getters['auth/isLoggedIn']) {
      next({
        name: 'Index',
        params: {
          portalId: to.params.portalId,
        },
      })
      return
    }
    next()
  },
  methods: {
    async handleSignup({ username, password }) {
      const result = await cognitoSignUp(username, password)

      if (result.isError) {
        this.errorCode = result.data.code
        return
      }

      this.$router.push({
        name: 'AccountVerifications',
        query: { username, redirect: this.$route.query.redirect },
      })
    },
  },
}
</script>
